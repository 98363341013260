<div class="container-fluid">
  <app-header></app-header>
  <router-outlet></router-outlet>
  <app-footer></app-footer>
</div>

<!-- <div>Icons made by 
  <a href="https://www.flaticon.com/authors/freepik" 
  title="Freepik">Freepik</a> 
  from <a href="https://www.flaticon.com/"     
  title="Flaticon">www.flaticon.com</a>
</div>
<div>Icons made by 
  <a href="https://www.flaticon.com/authors/smashicons" 
  title="Smashicons">Smashicons</a> 
  from <a href="https://www.flaticon.com/"     
  title="Flaticon">www.flaticon.com</a>
</div>
<div>Icons made by 
  <a href="https://www.flaticon.com/authors/prosymbols" 
  title="Prosymbols">Prosymbols</a> 
  from <a href="https://www.flaticon.com/"     
  title="Flaticon">www.flaticon.com</a>
</div>
<div>Icons made by 
  <a href="https://www.flaticon.com/authors/dimitry-miroliubov" 
  title="Dimitry Miroliubov">Dimitry Miroliubov</a> 
  from <a href="https://www.flaticon.com/"    
  title="Flaticon">www.flaticon.com</a>
</div>
<div>Icons made by 
  <a href="https://www.flaticon.com/authors/pixel-perfect" 
  title="Pixel perfect">Pixel perfect</a> 
  from <a href="https://www.flaticon.com/"     
  title="Flaticon">www.flaticon.com</a>
</div>
<div>Icons made by 
  <a href="https://www.flaticon.com/authors/iconixar" 
  title="iconixar">iconixar</a> 
  from <a href="https://www.flaticon.com/"     
  title="Flaticon">www.flaticon.com</a>
</div> 
Icons made by 
<a href="https://www.flaticon.com/authors/freepik" 
title="Freepik">Freepik</a> 
from <a href="https://www.flaticon.com/" 
title="Flaticon"> www.flaticon.com</a>
-->