
<div class="row footerStyle">
    <div class="col mx-auto border-top text-center py-5">
        <div class="pt-2">
            <a href="https://www.linkedin.com/in/ramsesl/" target="_blank">
                <img src="https://39275836.servicio-online.net/img/icon/linkedin.png" alt="linkedin" class="mx-1">
            </a>
            <a href="https://github.com/ramses04" target="_blank">
                <img src="https://39275836.servicio-online.net/img/icon/github.png" alt="linkedin" class="mx-1 bgFix">
            </a>
            <a routerLink="/contact">
                <img src="https://39275836.servicio-online.net/img/icon/letter.png" alt="mail" class="mx-1">
            </a>
        </div>
        <p class="mt-2">
            © 2023<a href="#" class="footerLink"> Ramses Liñayo</a>. All rights reserved.
        </p>   
    </div>
</div>
