import { Component, ViewEncapsulation, HostListener, OnInit } from '@angular/core';
import { DataService } from '../data.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class AboutComponent implements OnInit {
  public innerWidth: any;  
  screenSmall = false;
  screenSmallSize = 580;
  espLang: boolean;
  engLang: boolean;

  imglang = ['https://39275836.servicio-online.net/img/icon/html.png',
  'https://39275836.servicio-online.net/img/icon/css.png', 
  'https://39275836.servicio-online.net/img/icon/javascript.png',
  'https://39275836.servicio-online.net/img/icon/typescript.png',
  'https://39275836.servicio-online.net/img/icon/java.png',
  'https://39275836.servicio-online.net/img/icon/sql.png',];
  imgtools = ['https://39275836.servicio-online.net/img/icon/angular.png',
  'https://39275836.servicio-online.net/img/icon/bootstrap.png', 
  'https://39275836.servicio-online.net/img/icon/json.png',
  'https://39275836.servicio-online.net/img/icon/xml.png',  
  'https://39275836.servicio-online.net/img/icon/git.png'];
  
  constructor(private data: DataService) {
  }
  
  ngOnInit(){
    this.innerWidth = window.innerWidth;
    if (innerWidth < 580){
      this.screenSmall = true;
    } else {
      this.screenSmall = false;
    }
    
    this.data.currentEspLang.subscribe(espLang => this.espLang = espLang);
    this.data.currentEngLang.subscribe(engLang => this.engLang = engLang);
  }
  
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    
    if (innerWidth < 580){
      this.screenSmall = true;
    } else {
      this.screenSmall = false;
    }
  }  
  
  
}
