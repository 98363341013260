<!-- SPANISH SECTION -->
<div *ngIf="espLang">
	<div class="row my-5 justify-content-center">
		<div class="col-xs-12 col-sm-12 text-center my-5">
			<img src="https://39275836.servicio-online.net/img/icon/pencil.png" alt="ico" class="imgWork">
			<h1 class="mt-3 colorTitle">Mis trabajos recientes</h1>
		</div>
	</div>
</div>

<!-- ENGLISH SECTION -->
<div *ngIf="engLang">
	<div class="row my-5 justify-content-center">
		<div class="col-xs-12 col-sm-12 text-center my-5">
			<img src="https://39275836.servicio-online.net/img/icon/pencil.png" alt="ico" class="imgWork">
			<h1 class="mt-3 colorTitle">My recent works</h1>
		</div>
	</div>
</div>

<app-project></app-project>