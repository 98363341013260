<!-- SPANISH SECTION -->
<div *ngIf="espLang">    
    
    <div class="mt-5">
        <div class="row" *ngFor='let projectEsp of projectsEsp'>
            <div class="col text-center justify-content-center py-4" [ngStyle]="{'background-color':projectEsp.color}">
                <div class="my-5 text-white">
                    <div class="col-xs-12">
                        <img src="{{projectEsp.img}}" alt="{{projectEsp.name}}" class="imgFixed">
                    </div>
                    <div class="col-xs-12 mt-3">
                        <h1 class="mt-2"> {{ projectEsp.name }} </h1>
                    </div>
                    <div class="col-xs-12 mt-3">
                        <p class="mt-2 txtAdjust txtDescription"> {{ projectEsp.description }} </p>
                    </div>
                    <div class="col-xs-12 mt-3">
                        <button class="btn btn-warning btnStyleSite" style="min-width: 150px;">
                            <a class="text-white noDecoration" href="{{ projectEsp.link }}" target="_blank"> {{ projectEsp.btnMsg }} </a>
                        </button>
                    </div>                
                </div>            
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col text-center justify-content-center py-4" style="background-color: #cc6666;">
            <div class="my-5 text-white">
                <div class="col-xs-12">
                    <img src="https://39275836.servicio-online.net/img/devices/responsiveYou-esp.png" 
                    alt="Tu sitio web aquí" 
                    class="imgFixed">
                </div>
                <div class="col-xs-12 mt-3">
                    <h1 class="mt-2"> Tu sitio web aquí </h1>
                </div>
                <div class="col-xs-12 mt-3">
                    <p class="mt-2 txtAdjust txtDescription"> 
                        Ese posicionamiento en la web que necesita tú 
                        emprendimiento para seguir creciendo. Esa reestructuración 
                        que necesita esa vieja página web de tu empresa. 
                        Ese proyecto que llevas tiempo pensando iniciar y 
                        aún no te habías atrevido.
                    </p>
                </div>
                <div class="col-xs-12 mt-3">
                    <button class="btn btn-warning btnStyleSite" style="min-width: 150px;">
                        <a class="text-white noDecoration" routerLink="/contact">
                            Hagamos algo asombroso!
                        </a>
                    </button>
                </div>                
            </div>            
        </div>
    </div>
    
</div>

<!-- ENGLISH SECTION -->
<div *ngIf="engLang">
    
    <div class="mt-5">
        <div class="row" *ngFor='let projectEng of projectsEng'>
            <div class="col text-center justify-content-center py-4" [ngStyle]="{'background-color':projectEng.color}">
                <div class="my-5 text-white">
                    <div class="col-xs-12">
                        <img src="{{projectEng.img}}" alt="{{projectEng.name}}" class="imgFixed">
                    </div>
                    <div class="col-xs-12 mt-3">
                        <h1 class="mt-2"> {{ projectEng.name }} </h1>
                    </div>
                    <div class="col-xs-12 mt-3">
                        <p class="mt-2 txtAdjust txtDescription"> {{ projectEng.description }} </p>
                    </div>
                    <div class="col-xs-12 mt-3">
                        <button class="btn btn-warning btnStyleSite" style="min-width: 150px;">
                            <a class="text-white noDecoration" href="{{ projectEng.link }}" target="_blank"> {{ projectEng.btnMsg }} </a>
                        </button>
                    </div>                
                </div>            
            </div>
        </div>
    </div>
    
    <div class="row">
        <div class="col text-center justify-content-center py-4" style="background-color:#cc6666;">
            <div class="my-5 text-white">
                <div class="col-xs-12">
                    <img src="https://39275836.servicio-online.net/img/devices/responsiveYou-eng.png" 
                    alt="Your website here" 
                    class="imgFixed">
                </div>
                <div class="col-xs-12 mt-3">
                    <h1 class="mt-2"> Your website here </h1>
                </div>
                <div class="col-xs-12 mt-3">
                    <p class="mt-2 txtAdjust txtDescription">
                        That space on the web that your brand needs 
                        to keep growing, that update your old web needs to 
                        keep up with the latest technologies, that project 
                        you have been thinking about for a long time and 
                        still haven’t started.
                    </p>
                </div>
                <div class="col-xs-12 mt-3">
                    <button class="btn btn-warning btnStyleSite" style="min-width: 150px;">
                        <a class="text-white noDecoration" routerLink="/contact"> 
                            Let’s create something amazing!
                        </a>
                    </button>
                </div>                
            </div>            
        </div>
    </div>
    
</div>

