import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { DataService } from '../data.service';

@Component({
  selector: 'app-work',
  templateUrl: './work.component.html',
  styleUrls: ['./work.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class WorkComponent implements OnInit {

  espLang: boolean;
  engLang: boolean;

  constructor(private data: DataService) {   
   }

   ngOnInit() {
    this.data.currentEspLang.subscribe(espLang => this.espLang = espLang);
    this.data.currentEngLang.subscribe(engLang => this.engLang = engLang);
   }

}
