<div class="row" *ngIf="espLang">
  <nav class="navbar navbar-expand-sm navbar-light navbarStyle {{ marginFix }}">
    <div class="container" style="padding: 0;">
      <a class="navbar-brand" routerLink="/" (click)="onLogoSelected()">
        <img src="https://39275836.servicio-online.net/img/icon/avatar_me.png" alt="ico" class="imgLogoMe">      
      </a>
      <a class="flagMobile"         
      (click)="sendLang()"
      *ngIf="screenSmall">
        <img src="https://39275836.servicio-online.net/img/icon/esp.png" 
        alt="ESP" style="width:30px;">
      </a>
      <button class="navbar-toggler" type="button" (click)=" isCollapsed()">
        <span class="navbar-toggler-icon"></span>
      </button>    
      
      <div class="{{ showItems }} navbar-collapse">
        <ul class="navbar-nav ml-auto mr-0 mt-2 mt-lg-0">
          <li class="nav-item">
            <a class="navLink mx-2 {{ aboutIsSelected }}"
            routerLink="/"
            (click)="onAboutSelected()">Sobre mi</a>
          </li>
          <li class="nav-item">
            <a class="navLink mx-2 {{ workIsSelected }}"
            routerLink="/work"
            (click)="onWorkSelected()">Portafolio</a>
          </li>
          <li class="nav-item">
            <a class="navLink mx-2 {{ contactIsSelected }}" 
            routerLink="/contact"          
            (click)="onContactSelected()">Salúdame</a>
          </li>
          <li class="nav-item" *ngIf="!screenSmall">
            <a class="mx-2"         
            (click)="sendLang()"><img src="https://39275836.servicio-online.net/img/icon/esp.png" alt="ESP" style="width:30px;"></a>
          </li>
        </ul>
      </div>
    </div>    
  </nav>
</div>


<div class="row" *ngIf="engLang">
  <nav class="navbar navbar-expand-sm navbar-light navbarStyle {{ marginFix }}">
    <div class="container" style="padding: 0;">
      <a class="navbar-brand" routerLink="/" (click)="onLogoSelected()">
        <img src="https://39275836.servicio-online.net/img/icon/avatar_me.png" alt="ico" class="imgLogoMe">      
      </a>
      <a class="flagMobile"
      *ngIf="screenSmall"            
      (click)="sendLang()">
      <img src="https://39275836.servicio-online.net/img/icon/eng.png"
       alt="ENG" style="width:30px;">
      </a>

      <button class="navbar-toggler" type="button" (click)=" isCollapsed()">
        <span class="navbar-toggler-icon"></span>
      </button>    
      
      <div class="{{ showItems }} navbar-collapse">
        <ul class="navbar-nav ml-auto mr-0 mt-2 mt-lg-0">
          <li class="nav-item">
            <a class="navLink mx-2 {{ aboutIsSelected }}"
            routerLink="/"
            (click)="onAboutSelected()">About Me</a>
          </li>
          <li class="nav-item">
            <a class="navLink mx-2 {{ workIsSelected }}"
            routerLink="/work"
            (click)="onWorkSelected()">Portfolio</a>
          </li>
          <li class="nav-item">
            <a class="navLink mx-2 {{ contactIsSelected }}" 
            routerLink="/contact"          
            (click)="onContactSelected()">Say Hello</a>
          </li>
          <li class="nav-item" *ngIf="!screenSmall">
            <a class="mx-2"            
            (click)="sendLang()"><img src="https://39275836.servicio-online.net/img/icon/eng.png" alt="ENG" style="width:30px;"></a>
          </li>
        </ul>
      </div>
    </div>    
  </nav>
</div>
