<!-- SPANISH SECTION -->
<div *ngIf="espLang">
    <div class="row justify-content-center no-gutter mt-5">
        <div class="col text-center mt-5">
            <img src="https://39275836.servicio-online.net/img/icon/letter.png" alt="letter" style="max-width: 100px;">
            <h2 class="mt-3 colorTitle">Salúdame!</h2>
            <p class="txtAdjust">Ya sea para discutir sobre un 
                proyecto que tienes en mente, salir de alguna duda que 
                pudieras tener o contarme sobre un tesoro escondido. 
                Llena el formulario de abajo y me pondré en contacto contigo 
                tan pronto como pueda.
            </p>
        </div>    
    </div>
    
    <form action="https://39275836.servicio-online.net/sendMail.php" method="POST" class="mt-4 marginFix">
        <div class="row justify-content-center no-gutter ">
            <div class="col-xs-12 col-sm-5 col-md-4 col-lg-3 col-xl-2 form-group">            
                <label for="inputName" class="labelMobileFix"> Nombre</label>
                <input type="text" name="nombre" class="form-control txtInput mx-auto" 
                id="inputName" autocomplete="off" required>             
            </div>
            <div class="col-xs-12 col-sm-5 col-md-4 col-lg-3 col-xl-2 form-group">            
                <label for="inputMail" class="labelMobileFix">Correo electronico</label>
                <input type="email" name="email" class="form-control txtInput mx-auto" 
                id="inputMail" required>            
            </div>    
        </div>
        <div class="row justify-content-center no-gutter ">
            <div class="col-xs-12 col-sm-10 col-md-8 col-lg-6 col-xl-4 form-group">            
                <label for="inputMessage" class="labelMobileFix">Mensaje</label>
                <textarea name="mensaje" class="form-control txtareaInput mx-auto" 
                id="inputMessage" autocomplete="off" rows="5" required></textarea>          
            </div> 
        </div>
        <div class="row justify-content-center no-gutter">
            <div class="col-xs-12 col-sm-10 col-md-8 col-lg-6 col-xl-4 form-group text-center">    
                <button type="submit"
                class="btn btn-primary btnSend"
                >Enviar</button> 
            </div> 
        </div>
    </form>   
</div>

<!-- ENGLISH SECTION -->
<div *ngIf="engLang">
    <div class="row justify-content-center no-gutter mt-5">
        <div class="col text-center mt-5">
            <img src="https://39275836.servicio-online.net/img/icon/letter.png" alt="letter" style="max-width: 100px;">
            <h2 class="mt-3 colorTitle">Say Hi!</h2>
            <p class="txtAdjust">If you want to discuss about a 
                project you have in mind, you want to ask me something or 
                you want to tell me about a hidden treasure you know about. 
                Fill the form below and I’ll contact you soon!
            </p>
        </div>    
    </div>
    
    <form action="https://39275836.servicio-online.net/sendMail.php" method="POST" class="mt-4 marginFix">
        <div class="row justify-content-center no-gutter ">
            <div class="col-xs-12 col-sm-5 col-md-4 col-lg-3 col-xl-2 form-group">            
                <label for="inputName" class="labelMobileFix">Name</label>
                <input type="text" name="nombre" class="form-control txtInput mx-auto" 
                id="inputName" autocomplete="off" required>             
            </div>
            <div class="col-xs-12 col-sm-5 col-md-4 col-lg-3 col-xl-2 form-group">            
                <label for="inputMail" class="labelMobileFix">Email</label>
                <input type="email" name="email" class="form-control txtInput mx-auto" 
                id="inputMail" required>            
            </div>    
        </div>
        <div class="row justify-content-center no-gutter ">
            <div class="col-xs-12 col-sm-10 col-md-8 col-lg-6 col-xl-4 form-group">            
                <label for="inputMessage" class="labelMobileFix">Message</label>
                <textarea name="mensaje" class="form-control txtareaInput mx-auto" 
                id="inputMessage" autocomplete="off" rows="5" required></textarea>          
            </div> 
        </div>
        <div class="row justify-content-center no-gutter">
            <div class="col-xs-12 col-sm-10 col-md-8 col-lg-6 col-xl-4 form-group text-center">    
                <button type="submit"
                class="btn btn-primary btnSend">Send</button> 
            </div> 
        </div>
    </form>
</div>
