import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private langEspSource = new BehaviorSubject<boolean>(true);
  private langEngSource = new BehaviorSubject<boolean>(false);
  currentEspLang = this.langEspSource.asObservable();
  currentEngLang = this.langEngSource.asObservable();
  
  constructor() { }
  
  changeEspLang(espLang: boolean){
    this.langEspSource.next(espLang);
  }

  changeEngLang(engLang: boolean){
    this.langEngSource.next(engLang);
  }
  
}
