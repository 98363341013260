import { Component, OnInit, ViewEncapsulation, HostListener} from '@angular/core';
import { DataService } from '../data.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class HeaderComponent implements OnInit{
  showItems: string = 'collapse';
  aboutIsSelected: string = '';
  workIsSelected: string = '';
  contactIsSelected: string = '';
  marginFix: string = '';
  espLang: boolean = true;
  engLang: boolean = false;
  public innerWidth: any;  
  screenSmall = false;
  
  constructor(private data: DataService) {}
  
  ngOnInit() {
    this.innerWidth = window.innerWidth;
    if (innerWidth < 580){
      this.screenSmall = true;
    } else {
      this.screenSmall = false;
    }
    
    this.data.currentEspLang.subscribe(espLang => this.espLang = espLang);
    this.data.currentEngLang.subscribe(engLang => this.engLang = engLang);
  }
  
  isCollapsed (){
    if (this.showItems === 'collapse'){
      this.showItems='';
      this.marginFix = 'navbarMobileClicked';      
    } else if (this.showItems === ''){
      this.showItems = 'collapse';
      this.marginFix = '';
    }
  }
  
  onAboutSelected(){
    this.aboutIsSelected = 'active';
    this.workIsSelected = '';
    this.contactIsSelected = '';
    if (this.showItems === 'collapse'){
      this.showItems='';
      this.marginFix = 'navbarMobileClicked';      
    } else if (this.showItems === ''){
      this.showItems = 'collapse';
      this.marginFix = '';
    }
  }

  onLogoSelected(){
    this.aboutIsSelected = 'active';
    this.workIsSelected = '';
    this.contactIsSelected = '';
    if (this.showItems === ''){
      this.showItems = 'collapse';
      this.marginFix = '';
    }
  }
  
  onWorkSelected(){
    this.aboutIsSelected = '';
    this.workIsSelected = 'active';
    this.contactIsSelected = '';
    if (this.showItems === 'collapse'){
      this.showItems='';
      this.marginFix = 'navbarMobileClicked';      
    } else if (this.showItems === ''){
      this.showItems = 'collapse';
      this.marginFix = '';
    }
  }
  
  onContactSelected(){    
    this.aboutIsSelected = '';
    this.workIsSelected = '';
    this.contactIsSelected = 'active';
    if (this.showItems === 'collapse'){
      this.showItems='';
      this.marginFix = 'navbarMobileClicked';      
    } else if (this.showItems === ''){
      this.showItems = 'collapse';
      this.marginFix = '';
    }
  }
  
  sendLang() {
    if (this.espLang === true) {
      this.data.changeEspLang(false);
      this.data.changeEngLang(true);      
    } else {
      this.data.changeEspLang(true);
      this.data.changeEngLang(false);      
    }  
  }
  
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.innerWidth = window.innerWidth;
    
    if (innerWidth < 580){
      this.screenSmall = true;
    } else {
      this.screenSmall = false;
    }
  }  
  
}
