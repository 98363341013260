import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { DataService } from '../data.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ContactComponent implements OnInit {
  espLang: boolean;
  engLang: boolean;
  
  constructor(private data: DataService) { }
  
  ngOnInit() {
    this.data.currentEspLang.subscribe(espLang => this.espLang = espLang);
    this.data.currentEngLang.subscribe(engLang => this.engLang = engLang);  
  }
  
}
