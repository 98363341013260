import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { Project } from './project.model';
import { DataService } from '../../data.service';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ProjectComponent implements OnInit {
  espLang: boolean;
  engLang: boolean;
  projectsEsp: Project[] = [
    new Project('https://39275836.servicio-online.net/img/devices/webYogaimpacto.png',
    'yogaimpacto',
    'Un espacio desarrollado para una marca latinoamericana manejada por practicantes activos del yoga dedicada a la creación de productos para esta disciplina.',
    'http://yogaimpacto.epizy.com/',
    '#7c98ab',
    'Ver sitio'),
    new Project('https://39275836.servicio-online.net/img/devices/webClimApp.png',
    'climApp',
    'Una App para buscar el pronostico de una ciudad especifica o mirar los de la ciudad por defecto (Madrid) en tiempo real',
    'https://39275836.servicio-online.net/climApp',
    '#7cab83',
    'Ver sitio'),
    new Project('https://39275836.servicio-online.net/img/devices/webNotNews.png',
    'notNews',
    'Una web que simula un sitio de noticias recuperando los datos de manera dinamica y haciendo uso de la API de Twitter',
    'https://39275836.servicio-online.net/news',
    '#827cab',
    'Ver sitio'),
    new Project('https://39275836.servicio-online.net/img/devices/webMe.png',
    'ramseslinayo',
    'Mi carta de presentación profesional, en ella busco mostrar los proyectos a los que me vaya dedicando y las nuevas tecnologías que vaya aprendiendo.',
    '#',
    '#e6a5d0',
    'Ver sitio'),
    // new Project('https://39275836.servicio-online.net/img/devices/responsiveYou-esp.png',
    // 'project3',
    // 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean quis maximus odio. consectetur adipiscing elit. Aenean quis maximus odio.',
    // '#',
    // '#a5eb83',
    // 'Ver sitio'),
    // new Project('https://39275836.servicio-online.net/img/devices/responsiveYou-esp.png',
    // 'Tu sitio web aquí',
    // 'Ese posicionamiento en la web que necesita tú emprendimiento para seguir creciendo. Esa reestructuración que necesita esa vieja página web de tu empresa. Ese proyecto que llevas tiempo pensando iniciar y aun no te habías atrevido.',
    // 'https://ramseslinayo.com/contact',
    // '#cc6666',
    // 'Hagamos algo asombroso!'),    
  ];
  projectsEng: Project[] = [
    new Project('https://39275836.servicio-online.net/img/devices/webYogaimpacto.png',
    'yogaimpacto',
    'An app developed for a Latin-American brand created by active members from the yoga community, dedicated to design products for all the people on the discipline.',
    'http://yogaimpacto.epizy.com/',
    '#7c98ab',
    'Visit site'),
    new Project('https://39275836.servicio-online.net/img/devices/webClimApp.png',
    'climApp',
    'An app to search for the weather forecast of a specific city or view the default city forecast (Madrid) in real time',
    'https://39275836.servicio-online.net/climApp',
    '#7cab83',
    'Visit site'),
    new Project('https://39275836.servicio-online.net/img/devices/webNotNews.png',
    'notNews',
    'A website that simulates a news site by dynamically retrieving data and using the Twitter API',
    'https://39275836.servicio-online.net/news',
    '#827cab',
    'Ver sitio'),
    new Project('https://39275836.servicio-online.net/img/devices/webMe.png',
    'ramseslinayo',
    'My professional presentation in the web, in it I intend to show the projects I work in, the new techs I learn and more.',
    '#',
    '#e6a5d0',
    'Visit site'),
    // new Project('https://39275836.servicio-online.net/img/devices/responsiveYou-eng.png',
    // 'project3',
    // 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean quis maximus odio. consectetur adipiscing elit. Aenean quis maximus odio.',
    // '#',
    // '#a5eb83',
    // 'Visit site'),
    // new Project('https://39275836.servicio-online.net/img/devices/responsiveYou-eng.png',
    // 'Your website here',
    // 'That space on the web that your brand needs to keep growing, that update your old web needs to keep up with the latest technologies, that project you have been thinking about for a long time and still haven’t started. ',
    // 'https://ramseslinayo.com/contact',
    // '#cc6666',
    // 'Let’s create something amazing!'),    
  ]
  
  constructor(private data: DataService) {   }

  ngOnInit() {
    this.data.currentEspLang.subscribe(espLang => this.espLang = espLang);
    this.data.currentEngLang.subscribe(engLang => this.engLang = engLang);  }
  
}
