<!-- SPANISH SECTION -->
<div *ngIf="espLang">
	<div class="row mb-5">	
		<div class="col-12 imgHeader justify-content-center">
			<div class="containerIntro m-auto text-center">
				<div class="vAlignText">
					<h1 class="txtMainIntro MainText">Soy Ramses</h1>
					<h5 class="txtSecondIntro">un diseñador web.</h5>
				</div>					
			</div>		
		</div>
	</div>
	<div class="row justify-content-center py-5">
		<div class="col-xs-12 col-md-6 text-center">
			<img src="https://39275836.servicio-online.net/img/icon/avatar_me.png" alt="ico" class="imgMe">
			<h1 class="mt-3 colorTitle">Hola!</h1>
			<p class="txtAbout mt-2">Soy un programador especializado en el 
				desarrollo y diseño de aplicaciones web, actualmente ubicado 
				en Madrid, España. Apasionado por la tecnología y siempre 
				buscando la mejor forma de desarrollar mis proyectos.
			</p>
		</div>
	</div>
	
	<hr class="inclinedLineRight">
	
	<div class="row justify-content-center pt-5"> 
		<div class="col-xs-12 col-sm-2 col-xl-1 text-center">
			<img src="https://39275836.servicio-online.net//img/icon/draw.png" alt="Design" class="imgDesign">
		</div>  
		<div class="col-xs-12 col-sm-9 col-md-7 col-lg-6 col-xl-4 text-center">
			<h1 class="colorTitle">¿Que hago?</h1>
			<p class="txtAbout mt-3" >Le doy forma a esa idea que tienes. <br> Como desarrollador,
				mi trabajo es utilizar las últimas tecnologías para crear
				una aplicación web que represente lo que buscas,
				y que al mismo tiempo funcione a la perfección sin 
				importar desde que dispositivo la visiten.
			</p>
		</div>
	</div>
	<div class="row mt-4 pb-5">
		<div class="col text-center mt-5">
			<button class="btn btn-info btnStyleSite" style="min-width: 150px;">
				<a class="text-white noDecoration" routerLink="/work" (click)="onWorkSelected()"> Mira mis trabajos </a>
			</button>
		</div>            
	</div>
	
	<hr class="inclinedLineLeft">
	
	<div class="row justify-content-center pt-5 px-5">
		
		<div class="col-xs-12 text-center">
			<h1 class="mt-3 colorTitle">¿Como lo hago?</h1>
			<p class="txtAbout mt-2">Me encanta crear cosas desde cero, y 
				para ello utilizo diferentes lenguajes y 
				herramientas de desarrollo.
			</p>
		</div>
	</div>
	
	<div class="row justify-content-center mt-5 mb-2 px-1"> 
		<div class="col-xs-9 col-sm-6 col-lg-5 col-xl-3 text-center pt-2">
			<h4 class="colorSubtitle">Lenguajes que hablo:</h4>
			<p class="txtAbout mt-2" >HTML, CSS, JavaScript, TypeScript, JAVA, SQL.</p>
		</div>
		<div class="col-xs-3 col-sm-2 col-xl-1 text-center imgMarginFix">
			<ngb-carousel *ngIf="imglang"
			[showNavigationArrows]="false" 
			[showNavigationIndicators]="false"
			[pauseOnHover]="false"
			interval="5000"
			class="imgCarousel">
			<ng-template ngbSlide>
				<div class="picsum-img-wrapper">
					<img [src]="imglang[0]" alt="HTML" class="slide-bottom imgSliderFix">
				</div>
			</ng-template>
			<ng-template ngbSlide>
				<div class="picsum-img-wrapper">
					<img [src]="imglang[1]" alt="CSS" class="slide-bottom imgSliderFix">
				</div>
			</ng-template>
			<ng-template ngbSlide>
				<div class="picsum-img-wrapper">
					<img [src]="imglang[2]" alt="JavaScript" class="slide-bottom imgSliderFix">
				</div>
			</ng-template>
			<ng-template ngbSlide>
				<div class="picsum-img-wrapper">
					<img [src]="imglang[3]" alt="TypeScript" class="slide-bottom imgSliderFix">
				</div>
			</ng-template>
			<ng-template ngbSlide>
				<div class="picsum-img-wrapper">
					<img [src]="imglang[4]"  alt="Java" class="slide-bottom imgSliderFix">
				</div>
			</ng-template>
			<ng-template ngbSlide>
				<div class="picsum-img-wrapper">
					<img [src]="imglang[5]" alt="SQL" class="slide-bottom imgSliderFix">
				</div>
			</ng-template>
		</ngb-carousel>
	</div>  	
</div>

<div class="row justify-content-center py-5"> 
	<div *ngIf='!screenSmall' class="col-xs-3 col-sm-2 col-xl-1 text-center">
		<ngb-carousel *ngIf="imgtools"
		[showNavigationArrows]="false" 
		[showNavigationIndicators]="false"
		[pauseOnHover]="false"
		interval="5000"
		class="imgCarousel">
		<ng-template ngbSlide>
			<div class="picsum-img-wrapper">
				<img [src]="imgtools[0]" alt="Angular" class="slide-bottom imgSliderFix">
			</div>
		</ng-template>
		<ng-template ngbSlide>
			<div class="picsum-img-wrapper">
				<img [src]="imgtools[1]" alt="Bootstrap" class="slide-bottom imgSliderFix">
			</div>
		</ng-template>
		<ng-template ngbSlide>
			<div class="picsum-img-wrapper">
				<img [src]="imgtools[2]" alt="JSON" class="slide-bottom imgSliderFix">
			</div>
		</ng-template>
		<ng-template ngbSlide>
			<div class="picsum-img-wrapper">
				<img [src]="imgtools[3]" alt="XML" class="slide-bottom imgSliderFix">
			</div>
		</ng-template>
		<ng-template ngbSlide>
			<div class="picsum-img-wrapper">
				<img [src]="imgtools[4]" alt="git" class="slide-bottom imgSliderFix">
			</div>
		</ng-template>		
	</ngb-carousel>
</div>  
<div class="col-xs-9 col-sm-6 col-lg-5 col-xl-3 text-center pt-2">
	<h4 class="colorSubtitle">Herramientas que utilizo:</h4>
	<p class="txtAbout mt-2" >Angular, Bootstrap, AJAX, JSON, XML, Git.</p>
</div>
<div *ngIf='screenSmall' class="col-xs-3 col-sm-2 col-xl-1 text-center imgMarginFix ">
	<ngb-carousel *ngIf="imgtools"
	[showNavigationArrows]="false" 
	[showNavigationIndicators]="false"
	[pauseOnHover]="false"
	interval="5000"
	class="imgCarousel">
	<ng-template ngbSlide>
		<div class="picsum-img-wrapper">
			<img [src]="imgtools[0]" alt="Angular" class="slide-bottom imgSliderFix">
		</div>
	</ng-template>
	<ng-template ngbSlide>
		<div class="picsum-img-wrapper">
			<img [src]="imgtools[1]" alt="Bootstrap" class="slide-bottom imgSliderFix">
		</div>
	</ng-template>
	<ng-template ngbSlide>
		<div class="picsum-img-wrapper">
			<img [src]="imgtools[2]" alt="JSON" class="slide-bottom imgSliderFix">
		</div>
	</ng-template>
	<ng-template ngbSlide>
		<div class="picsum-img-wrapper">
			<img [src]="imgtools[3]" alt="XML" class="slide-bottom imgSliderFix">
		</div>
	</ng-template>
	<ng-template ngbSlide>
		<div class="picsum-img-wrapper">
			<img [src]="imgtools[4]" alt="git" class="slide-bottom imgSliderFix">
		</div>
	</ng-template>		
</ngb-carousel>
</div>  
</div>


<hr class="inclinedLineRight my-5">

<div class="row py-5 justify-content-center">
	<div class="col-xs-12 col-sm-12 text-center">
		<img src="https://39275836.servicio-online.net/img/icon/meComputer.png" alt="ico" class="imgMessageMe">
		<h1 class="mt-3 colorTitle">Hablemos!</h1>
		<h5 class="colorAvalible">Estoy disponible para trabajo como freelance.</h5>
		<p class="txtAbout mt-2">Si tienes algún proyecto que 
			quisieras discutir, tienes alguna sugerencia para mi sitio o 
			simplemente quieres saludarme. Entonces escríbeme unas líneas. 
		</p>
		<button class="btn btn-info btnStyleSite my-5" style="min-width: 150px;">
			<a class="text-white noDecoration" routerLink="/contact"> Escríbeme </a>
		</button>
	</div>
</div>
</div>

<!-- ENGLISH SECTION -->

<div *ngIf="engLang">
	<div class="row mb-5">	
		<div class="col-12 imgHeader justify-content-center">
			<div class="containerIntro m-auto text-center">
				<div class="vAlignText">
					<h1 class="txtMainIntro MainText">I’m Ramses</h1>
					<h5 class="txtSecondIntro">a web designer.</h5>
				</div>					
			</div>		
		</div>
	</div>
	<div class="row justify-content-center py-5">
		<div class="col-xs-12 col-md-6 text-center">
			<img src="https://39275836.servicio-online.net/img/icon/avatar_me.png" alt="ico" class="imgMe">
			<h1 class="mt-3 colorTitle">Hello!</h1>
			<p class="txtAbout mt-2">I am a programmer specializing in the 
				design and development of web applications, currently based 
				in Madrid, Spain. I am passionate about technology and I am constantly 
				looking for the best way to develop my projects.
			</p>
		</div>
	</div>
	
	<hr class="inclinedLineRight">
	
	<div class="row justify-content-center pt-5"> 
		<div class="col-xs-12 col-sm-2 col-xl-1 text-center">
			<img src="https://39275836.servicio-online.net//img/icon/draw.png" alt="Design" class="imgDesign">
		</div>  
		<div class="col-xs-12 col-sm-9 col-md-7 col-lg-6 col-xl-4 text-center">
			<h1 class="colorTitle">What do I do? </h1>
			<p class="txtAbout mt-3" >I give shape to your ideas. <br>
				 As a developer, my job consists on using the latest 
				 technologies to create a web application that represents 
				 your brand, and at the same time, works perfectly regardless
				 of the device.
			</p>
		</div>
	</div>
	<div class="row mt-4 pb-5">
		<div class="col text-center mt-5">
			<button class="btn btn-info btnStyleSite" style="min-width: 150px;">
				<a class="text-white noDecoration" routerLink="/work"> Look at my works </a>
			</button>
		</div>            
	</div>
	
	<hr class="inclinedLineLeft">
	
	<div class="row justify-content-center pt-5 px-5">
		
		<div class="col-xs-12 text-center">
			<h1 class="mt-3 colorTitle">How do I do it?</h1>
			<p class="txtAbout mt-2">I love to create things from scratch, 
				and for that I use various programming languages and 
				development tools.
			</p>
		</div>
	</div>
	
	<div class="row justify-content-center mt-5 mb-2 px-1"> 
		<div class="col-xs-9 col-sm-6 col-lg-5 col-xl-3 text-center pt-2">
			<h4 class="colorSubtitle">Languages I can speak:</h4>
			<p class="txtAbout mt-2" >HTML, CSS, JavaScript, TypeScript, JAVA, SQL.</p>
		</div>
		<div class="col-xs-3 col-sm-2 col-xl-1 text-center imgMarginFix">
			<ngb-carousel *ngIf="imglang"
			[showNavigationArrows]="false" 
			[showNavigationIndicators]="false"
			[pauseOnHover]="false"
			interval="5000"
			class="imgCarousel">
			<ng-template ngbSlide>
				<div class="picsum-img-wrapper">
					<img [src]="imglang[0]" alt="HTML" class="slide-bottom imgSliderFix">
				</div>
			</ng-template>
			<ng-template ngbSlide>
				<div class="picsum-img-wrapper">
					<img [src]="imglang[1]" alt="CSS" class="slide-bottom imgSliderFix">
				</div>
			</ng-template>
			<ng-template ngbSlide>
				<div class="picsum-img-wrapper">
					<img [src]="imglang[2]" alt="JavaScript" class="slide-bottom imgSliderFix">
				</div>
			</ng-template>
			<ng-template ngbSlide>
				<div class="picsum-img-wrapper">
					<img [src]="imglang[3]" alt="TypeScript" class="slide-bottom imgSliderFix">
				</div>
			</ng-template>
			<ng-template ngbSlide>
				<div class="picsum-img-wrapper">
					<img [src]="imglang[4]"  alt="Java" class="slide-bottom imgSliderFix">
				</div>
			</ng-template>
			<ng-template ngbSlide>
				<div class="picsum-img-wrapper">
					<img [src]="imglang[5]" alt="SQL" class="slide-bottom imgSliderFix">
				</div>
			</ng-template>
		</ngb-carousel>
	</div>  	
</div>

<div class="row justify-content-center py-5"> 
	<div *ngIf='!screenSmall' class="col-xs-3 col-sm-2 col-xl-1 text-center">
		<ngb-carousel *ngIf="imgtools"
		[showNavigationArrows]="false" 
		[showNavigationIndicators]="false"
		[pauseOnHover]="false"
		interval="5000"
		class="imgCarousel">
		<ng-template ngbSlide>
			<div class="picsum-img-wrapper">
				<img [src]="imgtools[0]" alt="Angular" class="slide-bottom imgSliderFix">
			</div>
		</ng-template>
		<ng-template ngbSlide>
			<div class="picsum-img-wrapper">
				<img [src]="imgtools[1]" alt="Bootstrap" class="slide-bottom imgSliderFix">
			</div>
		</ng-template>
		<ng-template ngbSlide>
			<div class="picsum-img-wrapper">
				<img [src]="imgtools[2]" alt="JSON" class="slide-bottom imgSliderFix">
			</div>
		</ng-template>
		<ng-template ngbSlide>
			<div class="picsum-img-wrapper">
				<img [src]="imgtools[3]" alt="XML" class="slide-bottom imgSliderFix">
			</div>
		</ng-template>
		<ng-template ngbSlide>
			<div class="picsum-img-wrapper">
				<img [src]="imgtools[4]" alt="git" class="slide-bottom imgSliderFix">
			</div>
		</ng-template>		
	</ngb-carousel>
</div>  
<div class="col-xs-9 col-sm-6 col-lg-5 col-xl-3 text-center pt-2">
	<h4 class="colorSubtitle">Dev tools I use:</h4>
	<p class="txtAbout mt-2" >Angular, Bootstrap, AJAX, JSON, XML, Git.</p>
</div>
<div *ngIf='screenSmall' class="col-xs-3 col-sm-2 col-xl-1 text-center imgMarginFix ">
	<ngb-carousel *ngIf="imgtools"
	[showNavigationArrows]="false" 
	[showNavigationIndicators]="false"
	[pauseOnHover]="false"
	interval="5000"
	class="imgCarousel">
	<ng-template ngbSlide>
		<div class="picsum-img-wrapper">
			<img [src]="imgtools[0]" alt="Angular" class="slide-bottom imgSliderFix">
		</div>
	</ng-template>
	<ng-template ngbSlide>
		<div class="picsum-img-wrapper">
			<img [src]="imgtools[1]" alt="Bootstrap" class="slide-bottom imgSliderFix">
		</div>
	</ng-template>
	<ng-template ngbSlide>
		<div class="picsum-img-wrapper">
			<img [src]="imgtools[2]" alt="JSON" class="slide-bottom imgSliderFix">
		</div>
	</ng-template>
	<ng-template ngbSlide>
		<div class="picsum-img-wrapper">
			<img [src]="imgtools[3]" alt="XML" class="slide-bottom imgSliderFix">
		</div>
	</ng-template>
	<ng-template ngbSlide>
		<div class="picsum-img-wrapper">
			<img [src]="imgtools[4]" alt="git" class="slide-bottom imgSliderFix">
		</div>
	</ng-template>		
</ngb-carousel>
</div>  
</div>


<hr class="inclinedLineRight my-5">

<div class="row py-5 justify-content-center">
	<div class="col-xs-12 col-sm-12 text-center">
		<img src="https://39275836.servicio-online.net/img/icon/meComputer.png" alt="ico" class="imgMessageMe">
		<h1 class="mt-3 colorTitle">Let’s talk!</h1>
		<h5 class="colorAvalible">I’m available for freelance work. </h5>
		<p class="txtAbout mt-2">If you have a project you want to discuss, 
			a suggestion for my website or you just want to say hello, drop 
			me a line.  
		</p>
		<button class="btn btn-info btnStyleSite my-5" style="min-width: 150px;">
			<a class="text-white noDecoration" routerLink="/contact"> Message me </a>
		</button>
	</div>
</div>
</div>
